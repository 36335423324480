.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.postsContainer {
  height: 100%;
  width: 100%;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.postCard {
  background-color: orange;
  width: 50%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: x-large;
}

.username {
  font-size: small;
  font-weight: 200;
}

.content {
  display: flex;
}

.upvote {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  margin-bottom: 5px;

  border-bottom: 15px solid gray;
}

.downvote {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  margin-top: 5px;

  border-top: 15px solid gray;
}

.leftPost {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 10px;
}

.rightPost {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.comments {
  margin: 15px 10px;
}

.comment {
  margin-top: 10px;
}
